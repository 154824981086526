.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App a {
  color: #61dafb;
}

.App-genius_bar-progress {
  height: 5px;
  background-color: gold;
  transition: width 1s;
  border-radius: 2px;
  position: fixed;
  z-index: 10;
}

.App-points {
  font-size: 28px;
  font-weight: bold;
  margin-top: 15px;
}

.App-game {
  display: flex;
  flex-direction: column;
  margin-top: 165px;
}

.App-letters_container {
  position: relative;
  height: 150px;
  width: 10px;
  margin-bottom: 36px;
}

.App-letter {
  position: absolute;
  font-weight: bold;
  font-size: 26px;
  text-transform: uppercase;
  cursor: pointer;
}

.App-inner_circle {
  position: absolute;
  height: 75px;
  width: 75px;
  border: 5px solid gold;
  border-radius: 50%;
  right: -37px;
  top: -23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-outer_circle {
  position: absolute;
  height: 275px;
  width: 275px;
  border: 5px solid white;
  border-radius: 50%;
  right: -134px;
  top: -125px;
}

.App-rotate_icon {
  right: -150px;
  bottom: -18px;
  position: absolute;
  cursor: pointer;
}

.App-letter-0 {
  right: 100px;
}
.App-letter-1 {
  right: -100px;
}
.App-letter-2 {
  top: 86.6px;
  right: -50px;
}
.App-letter-3 {
  top: -86.6px;
  right: -50px;
}
.App-letter-4 {
  top: 86.6px;
  right: 50px;
}
.App-letter-5 {
  top: -86.6px;
  right: 50px;
}

.App-input_container {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 3px solid white;
  width: 300px;
  transition: border-bottom-color 0.25s;
  position: relative;
}

.App-input-mobile {
  position: absolute;
  top: 18px;
  right: -5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.App-input_delete_icon,
.App-input_enter_icon {
  height: 22px;
  width: 33px;
}

.App-input_container.green {
  border-bottom-color: mediumseagreen;
}

.App-input_container.red {
  border-bottom-color: salmon;
}

.App-input_container.yellow {
  border-bottom-color: orange;
}

.App-input_form {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.App-input {
  background-color: #282c34;
  color: white;
  font-size: 24px;
  padding: 9px;
  font-weight: 700;
  text-transform: uppercase;
}

.App-input:after {
  content: "|";
  color: lightgray;
}

.App-input_letter {
  color: lightgray;
}

.App-input_letter.valid {
  color: white;
}

.App-input_letter.valid.center {
  color: gold;
}

.App-found_list {
  text-transform: uppercase;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  width: 300px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0.75;
  line-height: 1.75em;
}

.App-found_word {
  margin-right: 12px;
}

.App-found_word:last-child {
  margin-right: 0px;
}

.App-found_word.pangram:after {
  content: "*";
  color: gold;
  font-size: 14px;
  vertical-align: text-top;
}

.App-found_word-letter.center {
  color: gold;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
